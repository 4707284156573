// extracted by mini-css-extract-plugin
export var box = "project-module--box--930f5";
export var design = "project-module--design--a2ae5";
export var design__media = "project-module--design__media--04eb7";
export var hero = "project-module--hero--2e853";
export var img_caption = "project-module--img_caption--4ac05";
export var img_caption_overlay = "project-module--img_caption_overlay--fc1b4";
export var intro = "project-module--intro--23aa5";
export var magic = "project-module--magic--5e0e3";
export var overviewCaseStudy = "project-module--overviewCaseStudy--dce3b";
export var overviewCaseStudy__content = "project-module--overviewCaseStudy__content--34215";
export var overviewCaseStudy__title = "project-module--overviewCaseStudy__title--fae21";
export var overviewList = "project-module--overviewList--dfd1b";
export var overviewList__more_link = "project-module--overviewList__more_link--d51b3";
export var subtitle = "project-module--subtitle--bc672";
export var title = "project-module--title--36f2e";