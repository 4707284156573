import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { siteMetadata } from "../../gatsby-config";
import { SectionAnimated, DivAnimated } from "../components/AnimatedSection";
import Layout from "../components/layout";
import {
  hero,
  design,
  design__media,
  intro,
  overviewCaseStudy,
  overviewCaseStudy__content,
  overviewCaseStudy__title,
  overviewList,
  subtitle,
  title,
} from "../components/project.module.scss";

const ProjectGreenLabel = () => {
  const imgVariants = {
    animate: { opacity: 1, scale: 1, y: 0 },
    initial: {
      opacity: 0,
      scale: 0.95,
      y: 0,
    },
  };
  return (
    <>
      <Helmet>
        <body className="dark-header" />
        <title>Green Label</title>
        <link rel="canonical" href={siteMetadata.siteUrl} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <div className="container-fluid" style={{ padding: 0 }}>
          <div style={{ background: "#347B28" }}>
            <div className="row">
              <motion.div
                className={title}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 1,
                  },
                }}
              >
                <h1 style={{ color: "#ffffff" }}>Green Label</h1>
              </motion.div>
              <div className="col-12">
                <section className={intro}>
                  <motion.h2
                    style={{ color: "#ffffff" }}
                    className={subtitle}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 1,
                      },
                    }}
                  >
                    React Site Prototype | Personal Project
                  </motion.h2>
                </section>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <motion.div
                  variants={imgVariants}
                  initial="initial"
                  animate="animate"
                  transition={{ duration: 0.8, ease: "easeIn" }}
                >
                  <StaticImage
                    src="../images/teaser-green-label.webp"
                    quality="100"
                    loading="eager"
                    placeholder="blurred"
                    layout="fullWidth"
                    className={hero}
                    alt="Desktop and mobile designs of department websites"
                  />
                </motion.div>
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ color: "#ffffff" }}>
                <section className={overviewList}>
                  <div className="col-md-6">
                    <h3>Personal Project</h3>
                    <p>
                      A product listing directory to help users find
                      eco-friendly and sustainable products online
                    </p>
                    <h3>Tools</h3>
                    <p>
                      React, Next.js, Material UI, MongoDB, Algolia Search,
                      Figma
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h3>Prototype Link</h3>
                    <p>
                      <a
                        href="https://green-label-prototype.vercel.app"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Green Label
                      </a>
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SectionAnimated cn={overviewCaseStudy}>
                <div className={overviewCaseStudy__title}>
                  <h2>About the Project</h2>
                </div>
                <div className={overviewCaseStudy__content}>
                  <p>
                    Green Label is a prototype directory application for
                    eco-friendly and sustainable products available online. The{" "}
                    <a
                      href="https://green-label-prototype.vercel.app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      initial implementation
                    </a>
                    , which was built using Next.js, features categorized
                    product listings using Algolia search and the option to
                    create a list of favorite products using MongoDB.
                  </p>
                  <p>
                    The designs below were created in Figma and illustrate the
                    project concept. With climate change and the significant
                    increase in e-commerce sales during the pandemic, purchasing
                    environmentally friendly products has become increasingly
                    important. In addition to making it easier for users to find
                    these products, the site's goal is to educate consumers by
                    providing transparent information about the products and
                    their materials.
                  </p>
                </div>
              </SectionAnimated>
            </div>
          </div>
          <DivAnimated cn={design} style={{ background: "#c6d1de" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/green-label-desktop-1.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Desktop designs of the site's brand detail page, homepage, and brands' listing page."
              />
            </div>
          </DivAnimated>
          <DivAnimated cn={design} style={{ background: "#347B28" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/green-label-mobile-1.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Mobile designs of the site's homepage, category search listing, brand detail page, and user profile page."
              />
            </div>
          </DivAnimated>
          <DivAnimated cn={design} style={{ background: "#c6d1de" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/green-label-desktop-2.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Desktop designs of the site's user profile page, category search listing, and eco guides page."
              />
            </div>
          </DivAnimated>
        </div>
      </Layout>
    </>
  );
};

export default ProjectGreenLabel;
